import React from 'react';
import Card from './Card/Card';
import './Platform.scss';
import data from './data.js';

const Platform = () => {

  return (
    <>
      <div className='Platform'>
        <div className='text-platform'>
          <p> A Service Platform in your hands</p>
          <p>We have more than 100 services on our platform that will accelerate your application</p>
        </div>
        <ul className='carrousel-platform has-scrollbar'>
        {data.map((item, key) => {
            return <li><Card data={item} /></li>
          })}
        </ul>
      </div>
    </>
  );
}

export default Platform;