const data = [
  {
    title: "Market Data",
    describe: "Collect and use live Market Data with more than 10,000 tokens available"
  },
  {
    title: "Chain API",
    describe: "Use blockchain as a service, send transactions without dealing with per chain implementation",
    image: "api"
  },
  {
    title: "KPRICE",
    describe: "Use our services in your products easily and with cost managed by yourself"
  },
  {
    title: "KDATA",
    describe: "Use our services in your products easily and with cost managed by yourself"
  },
  {
    title: "KPRICE",
    describe: "Use our services in your products easily and with cost managed by yourself"
  },
  {
    title: "KDATA",
    describe: "Use our services in your products easily and with cost managed by yourself"
  },
  {
    title: "KPRICE",
    describe: "Use our services in your products easily and with cost managed by yourself"
  },
  {
    title: "KDATA",
    describe: "Use our services in your products easily and with cost managed by yourself"
  }
]

export default data;
