import React from 'react';
import './Button.scss'

const Button = ({size, children, bg}) => {
  return (
    <button className={"Button " + size} style={{backgroundColor: bg}}>
      {children}
    </button>
  );
};

export default Button;
