import Achievements from './Achievements/Achievements';
import './App.scss';
import Footer from './Footer/Footer';
import HomeStarter from './HomeStarter/HomeStarter';
import Navbar from "./navbar/Navbar";
import Plans from './Plans/Plans';
import Platform from './Platform/Platform';
import SectionContact from './SectionContact/SectionContact';

function App() {
  return (
    <div className="App">
      <Navbar />
      <HomeStarter />
      <Achievements />
      <Platform />
      <Plans />
      <SectionContact />
      <Footer />
    </div>
  );
}

export default App;
