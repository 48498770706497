import React, { useState } from "react";
import PropTypes from "prop-types";
import { ReactComponent as Help } from "../../../assets/images/help.svg";
import { ReactComponent as Email } from "../../../assets/images/email.svg";
import clipboardImage from "../../../assets/images/clipboard.svg";

import "./Input.scss";

const Input = ({
  value,
  label,
  updateState,
  placeholder,
  maxLength,
  displayError,
  required,
  type,
  valid,
  disabled,
  errorMessage,
  visible,
  tooltip,
  email,
  clipboard
}) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const handleChange = e => {
    updateState(e.target.value);
  };
  
  return (
    <div style={{ display: visible ? "block" : "none" }} className="Input">
      {label ? (
        <label>
          {email && <Email style={{marginRight: "5px"}} />}
          {label}
          <div
            style={{ display: tooltip ? "flex" : "none" }}
            className="tooltip"
          >
            <span
              onMouseEnter={() => setTooltipVisible(true)}
              onMouseLeave={() => setTooltipVisible(false)}
            >
              <Help />
            </span>
            <div
              onClick={() => setTooltipVisible(false)}
              className={tooltipVisible && "visible"}
            >
              <p>{tooltip}</p>
            </div>
          </div>
        </label>
      ) : null}
      <div className="input-container">
        <input
          // style={{borderRight: `${clipboard && "unset"}`}}
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
          maxLength={maxLength}
          className={displayError && !valid ? "show-error" : ""}
          type={type}
          disabled={disabled}
        />
         {clipboard && <button onClick={() => navigator.clipboard.writeText(value)}><img src={clipboardImage} alt="Arrow Icon"/></button>}
      </div>
      <p
        className={`feedback-message ${
          displayError && !valid ? "visible" : ""
        }`}
      >
        {required && value === "" ? "Campo obrigatório" : errorMessage}
      </p>
    </div>
  );
};

Input.propTypes = {
  displayError: PropTypes.bool,
  errorMessage: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  maxLength: PropTypes.number,
  placeholder: PropTypes.string.isRequired,
  required: PropTypes.bool,
  type: PropTypes.string.isRequired,
  updateState: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
  valid: PropTypes.bool
};

Input.defaultProps = {
  errorMessage: "Mensagem de erro",
  label: "",
  placeholder: "",
  type: "text",
  value: "",
  visible: true
};

export default Input;
