import React, { useState } from 'react';
import Button from '../commons/Button/Button';
import Input from '../commons/Input/Input';
import Select from '../commons/Select/Select';
import { useTextField, useEmailField } from '../hooks/formHooks';
import image from './../../assets/images/sectionContact.svg';
import emailImage from './../../assets/images/email.svg';
import './SectionContact.scss';

const SectionContact = () => {
  const [operation, setOperation] = useTextField("Standart Plan ($49)");
  const [email, setEmail, validEmail] = useEmailField("");
  const [key, setKey] = useTextField("");
  const [payment, setPayment] = useTextField("");
  const [displayError, setDisplayError] = useState(false);

  return (
    <div className='SectionContact'>
      <div className='vector-section' />
      <img src={image} />
      <div className='form-contact'>
        <h3>Boost your Application</h3>
        <h5>Enter your email and click the button below to generate your api key and payment address. Information will be sent to you via email.</h5>
        <Select
          updateState={setOperation}
          label="Select Your Plan"
          value={operation}
          displayError={false}>
          <option selected value="" disabled> Selecione a operação</option>
          <option value="Standart Plan ($49)">Standart Plan ($49)</option>
        </Select>
        <Input
          email
          label={"E-mail"}
          value={email}
          valid={validEmail}
          placeholder={"Your e-mail here"}
          displayError={displayError}
          updateState={setEmail}
          required
          type={"email"}
        />
        <Button children="Generate your API Key" />
        <Input
          clipboard
          value={key}
          placeholder={"Your key will appear here"}
          displayError={displayError}
          updateState={setKey}
          required
          type={"text"}
        />
        <Input
          clipboard
          value={payment}
          placeholder={"Address to make payment in KLV TRC20"}
          displayError={displayError}
          updateState={setPayment}
          required
          type={"text"}
        />
      </div>
    </div>
  );
}

export default SectionContact;