import React from 'react';
import './PlansCard.scss'
import check from './../../../assets/images/check.svg'
import checkWhite from './../../../assets/images/check-white.svg'
import Button from '../../commons/Button/Button';

const PlansCard = ({ data }) => {
  return (
    <div style={{position: "relative"}}>
      {data.recommended && <div className='recommended'>
        <p>RECOMMENDED</p>
      </div>}

      <div className='PlansCard' style={{ background: data?.color, color: data?.textColor }}>
        <div className='infos'>
          <h3 style={{ color: data?.textColor }}>{data.title}</h3>
          <p style={{ color: data?.textColor }}>{data.sub}</p>
          <p style={{ color: data?.textColor }}>{data.describe}</p>
          {data.checks?.map((item, key) => {
            return <>
              <div className='check-text' key={key}>
                <img src={data.check == "blue" ? check : checkWhite} />
                <p style={{ color: data?.textColor }}>{item?.title}</p>
              </div>
            </>
          })}

        </div>
        <Button bg={data.bgButton} children={data.buttonText} />
      </div>
    </div>

  );
}

export default PlansCard;