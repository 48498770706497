import React from 'react';
import Button from '../commons/Button/Button';
import './HomeStarter.scss'
import image from './../../assets/images/image-home-starter.svg'

const HomeStarter = () => {
  return (
    <>
      <div className='HomeStarter'>
        <div className='starter'>
          <div className='texts-starter'>
            <h1>Production-Ready and Simplified</h1>
            <h1>Services for everyone</h1>
            <p>Use our services in your products easily and without burocracy</p>
            <Button children="Start now" size="larger" />
          </div>
          <div className='image-starter'>
            <img src={image} />
          </div>
        </div>

      </div>
    </>
  );
}

export default HomeStarter;