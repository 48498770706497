import React from 'react';
import './Card.scss'
import image from './../../../assets/images/fingerprint.svg'
import imageApi from './../../../assets/images/api.svg'

const Card = ({data}) => {
  return (
    <div className='Card'>
      <div className='blur-card' />
      <img src={data.image == "api" ? imageApi : image}/>
      <p>{data.title}</p>
      <p>{data.describe}</p>
    </div>
  );
}

export default Card;