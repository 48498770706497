import React from 'react';
import './Achievements.scss';

const Achievements = () => {
  return (<>
    <div className='Achievements'>
      <div className='vector-achie' />
      <div className='titles-achievements'>
        <h2>Achievements</h2>
        <h2>Full Decentralized and Currency Security</h2>
      </div>
      <div className='scores-achievements'>
        <div className='score'>
          <p>100+</p>
          <p>API’s Services</p>
        </div>
        <div className='score'>
          <p>200K+</p>
          <p>Community Assets</p>
        </div>
        <div className='score'>
          <p>20K+</p>
          <p>Build Community</p>
        </div>
      </div>
    </div>
  </>);
}

export default Achievements;