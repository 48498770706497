import React, { useState } from 'react';
import './Navbar.scss'
import logo from './../../assets/images/logo-navbar.svg'
import arrow from './../../assets/images/arrow-down.svg'
import light from './../../assets/images/light-mode.svg'

const Navbar = () => {
  let hover = false;
  const [hovering, update] = useState(false);

  return (
    <>
      <div className="Navbar">
        <img src={logo} alt="" />
        <div className="selects"
          onMouseOver={() => {
            hover = true;
            setTimeout(() => {
              if (hover) {
                update(true);
              }
            }, 100);
          }}
          onMouseOut={() => {
            hover = false;
            update(false);
          }}>
          <div className='select-language'>
            <a>
              <span >English / EN</span>
              <img src={arrow} />
            </a>
            <p />
            <img src={light} />
          </div>
        </div>
        {hovering && <div className="text-navbar"> Coming soon </div>}
      </div>
    </>
  );
};

export default Navbar;
