import React from 'react';
import './Footer.scss';
import logo from './../../assets/images/logo-footer.svg';

const Footer = () => {
  return (
    <div className='Footer'>
      <div className='header-footer'>
        <div className='image-footer'>
          <img src={logo} />
        </div>
        <div className='buttons-footer'>
          <div>
            <ul>Support</ul>
            <li>SUBMIT A TICKET</li>
            <li>TELEGRAM</li>
            <li>SUPPORT CENTER</li>
          </div>
          <div>
            <div>
              <ul>Mobile App</ul>
              <li>Mobile App</li>
              <li>Mobile App</li>
            </div>
            <div className='desk-desc'>
              <ul>Desktop App</ul>
              <li>IOS</li>
              <li>WINDOWS</li>
              <li>LINUX</li>
            </div>
          </div>
          <div>
            <ul>Links</ul>
            <li>BUY BITCOIN</li>
            <li>GETTY/IO BLOG</li>
            <li>TERMS OF USE</li>
            <li>TRONSCAN.ORG</li>
            <li>TRON FOUNDATION</li>
            <li>GETTY.IO INC.</li>
            <li>DAPPS STORE</li>
          </div>
        </div>
      </div>
      <div className='bottom-footer'>
        <div>
          <p>©2022 Klever</p>
        </div>
        <div>
          <p>Privacy Policy</p>
          <p>Terms of Use</p>
        </div>
      </div>
    </div>
  );
}

export default Footer;