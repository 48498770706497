import React from 'react';
import './Plans.scss'
import PlansCard from './PlansCard/PlansCard';
import dataPlans from './data.js';

const Plans = () => {

  return (
    <div className='Plans'>
      <div className='titles-plans'>
        <p>Discover our plans</p>
        <p>Our plans give you access to our most complete platform for web3 applications with the cost-effectiveness that will facilitate your goals</p>
      </div>
      <ul className='list-cards has-scrollbar'>
        {dataPlans.map((item, key) => {
          return <li><PlansCard data={item} key={key} /></li>
        })}
      </ul>
    </div>
  );
}

export default Plans;