const dataPlans = [
  {
    title: "Free",
    sub: "100 requests per day",
    describe: "Get access to our apis and discover all the potential. Recommended for those who want to learn and test our platform",
    checks: [
      { title: "25 requests / sec" },
      { title: "Unlimited services" },
    ],
    bgButton: "rgb(200 201 231 / 30%);",
    color: "#ffffff",
    buttonText: "Choose  free plan",
    textColor: "#000000",
    check: "blue"
  },
  {
    title: "Standard",
    sub: "100.000 requests per day",
    describe: "This plan is the starting point for your company, with more requests and greater possibilities for growth.",
    checks: [
      {title: "100 requests / sec"},
      {title: "Unlimited Services"},
      {title: "24hrs Support"}
    ],
    bgButton: "#ffffff",
    color: "linear-gradient(135deg, #466FFF 1.18%, #44B6F6 101.34%)",
    buttonText: "Choose plan ($49)*",
    textColor: "#ffffff",
    check: "white"
  },
  {
    title: "Premium",
    sub: "500.000 requests per day",
    describe: "This plan is the most business-oriented with bulk requests to boost your company and have peace of mind with large requests",
    checks: [
      {title: "350 requests / sec"},
      {title: "Unlimited Services"},
      {title: "24hrs Support"}
    ],
    bgButton: "#ffffff",
    color: "#222345",
    buttonText: "Choose plan ($99)*",
    textColor: "#ffffff",
    check: "white",
    recommended: "true"
  }
]

export default dataPlans;